<template>
  <div>
    <div class="w-100 pa4 pb2 ba br2 b--moon-gray bg-light-gray">
      <div class="w-100 flex">
        <div v-if="hospitals.length > 0" class="w-40 dib mr2">
          <label for="hospital" class="b db mb1">Hospital</label>
          <select id="hospital" class="w-100 f6 h2 ph1 br2 bg-white b--black-20 border-box" v-model="hospitalId">
            <option :value="''">All</option>
            <option v-for="hospital in hospitals" :key="hospital.id" :value="hospital.id">
              {{ hospital.name }}
            </option>
          </select>
        </div>

        <div class="w-40 dib mr2">
          <label for="series" class="b db mb1">Series</label>
          <select id="series" class="db w-100 pa2 br2 bn f6" v-model="seriesId">
            <option v-for="s in series" :value="s.id">
              {{ s.name }}
            </option>
          </select>
        </div>

        <div class="w-25 dib mr2">
          <label for="start-date" class="b db mb1">Start date</label>
          <date-picker
            v-model="startDate"
            format="YYYY-MM-DD"
            :editable="false"
            :disabled-date="fromToday"
            :clearable="false"
          />
        </div>

        <div class="w-25 dib mr2">
          <label for="end-date" class="b db mb1">End date</label>
          <date-picker
            v-model="endDate"
            format="YYYY-MM-DD"
            :editable="false"
            :disabled-date="fromToday"
            :clearable="false"
          />
        </div>

        <div class="w-10 dib">
          <label class="db">&nbsp;</label>
          <button
            @click="updateCharts()"
            class="mt1 w-100 tc pv2 br2 bn bg-blue white grow pointer"
          >Update</button>
        </div>
      </div>

      <table class="w-100 collapse mt2">
        <tr>
          <td class="pr2"><div class="w-100 pv2 b">Preset periods</div></td>
          <td class="pr2"><button @click="datePreset('month')" class="w-100 tc pv2 br2 bn bg-orange white grow pointer">Past month</button></td>
          <td class="pr2"><button @click="datePreset('6month')" class="w-100 tc pv2 br2 bn bg-orange white grow pointer">Past 6 months</button></td>
          <td class="pr2"><button @click="datePreset('12month')" class="w-100 tc pv2 br2 bn bg-orange white grow pointer">Past 12 months</button></td>
          <td class="pr2"><button @click="datePreset('quarter')" class="w-100 tc pv2 br2 bn bg-orange white grow pointer">Last quarter</button></td>
          <td class="pr0"><button @click="datePreset('year')" class="w-100 tc pv2 br2 bn bg-orange white grow pointer">Last year</button></td>
        </tr>
      </table>

      <div class="mt3 mb0">
        <small>Custom date ranges may result in slower metric loads.</small>
      </div>
    </div>

   <section
      v-if="seriesId"
      v-for="name in chartNames"
      class="w-100 hidden ba br2 b--moon-gray mv3"
    >
      <div class="bg-light-gray dark-gray mv0 pv2 ph3">
        {{ name | nameToTitle }}
        <info :description="chartDescription(name)"></info>
      </div>
      <div class="pa4 bt b--moon-gray">
        <component :is="name + '-chart'"
          :endPoint="(name + '-chart') | nameToEndPoint"
          :seriesId="seriesId"
          :startDate="startDate"
          :endDate="endDate"
          :hospitalId="hospitalId"
          :updateTrigger="updateTrigger"
          @mounted="mountChart"
        />
      </div>
    </section>
  </div>
</template>

<script>
import Info from "./Info.vue";
import ClicksChart from './ClicksChart';
import EngagementChart from './EngagementChart';
import ExperienceChart from './ExperienceChart';
import MessagesChart from './MessagesChart';
import PatientPopulationChart from './PatientPopulationChart';
import PatientReachChart from './PatientReachChart';
import UnsubscribeRateChart from './UnsubscribeRateChart';
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

export default {
  props: ["series", "hospitals"],
  components: {
    Info,
    ClicksChart,
    EngagementChart,
    ExperienceChart,
    MessagesChart,
    PatientPopulationChart,
    PatientReachChart,
    UnsubscribeRateChart,
    DatePicker
  },
  data() {
    return {
      hospitalId: "",
      seriesId: "",
      startDate: "",
      endDate: "",
      updateTrigger: 0,
      mountedCharts: 0,
      chartNames: [
        "experience",
        "patient-population",
        "patient-reach",
        "engagement",
        "messages",
        "unsubscribe-rate",
        "clicks"
      ]
    }
  },
  mounted() {
    if (this.series.length) {
      this.seriesId = this.series[0].id
    }
  },
  methods: {
    mountChart() {
      this.mountedCharts++
      if (this.mountedCharts >= this.chartNames.length) {
        if (this.seriesId) { this.datePreset("6month") }
      }
    },
    updateCharts() {
      this.updateTrigger += 1
    },
    datePreset(preset) {
      this.startDate = new Date()
      this.startDate.setDate(1)
      this.endDate = new Date()
      this.endDate.setDate(0)
      if (preset === "month") {
        this.startDate.setMonth(new Date().getMonth() - 1)
      } else if (preset === "6month") {
        this.startDate.setMonth(new Date().getMonth() - 6)
      } else if (preset === "12month") {
        this.startDate.setMonth(new Date().getMonth() - 12)
      } else if (preset === "quarter") {
        var q = Math.floor((new Date().getMonth() / 3))
        this.startDate = new Date(new Date().getFullYear(), q * 3 - 3, 1)
        this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 3, 0)
      } else if (preset === "year") {
        this.startDate = new Date(new Date().getFullYear() - 1, 0, 1)
        this.endDate = new Date(new Date().getFullYear() - 1, 11, 31)
      }
      this.updateCharts()
    },
    fromToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0))
    },
    chartDescription(name) {
      let desc
      switch (name) {
        case "experience":
          desc = "Data from the 90-day post-event Satisfaction Survey for selected Series"
          break
        case "patient-population":
          desc = `<b>Active:</b> active subscriptions during selected period<br>
                  <b>New:</b> new subscriptions during selected period<br>
                  <b>Total:</b> all subscriptions of all statuses during selected period`
          break
        case "patient-reach":
          desc = `<b>Delivered:</b> Emails and Texts received<br>
                  <b>Opened:</b> Emails opened plus Texts, if tapped<br>
                  <b>Clicked:</b> Emails in which link clicked<br>
                  <b>Text tap:</b> Texts in which link tapped<br>`
          break
        case "engagement":
          desc = `<b>Total Interactions:</b> Sum of all the metrics shown in this panel for selected time period<br>
                  <b>Emails:</b> Counts for series and campaign messages via email<br>
                  <b>Surveys:</b> Counts for survey responses (via email or text)<br>
                  <b>Web:</b> Counts for private 1-to-1 messages sent and received, plus all links clicked<br>`
          break
        case "messages":
          desc = `<b>Delivered:</b> Emails and Texts received<br>
                  <b>Opened:</b> Emails opened plus Texts, if tapped<br>
                  <b>Clicked:</b> Emails in which link clicked<br>
                  <b>Text tap:</b> Texts in which link tapped<br>
                  <b>Open Rate:</b> Opens divided by delivered. <b>(Healthcare Industry avg. = 22%)</b><br>
                  <b>Email Click Rate (Click to Open Rate):</b> Clicks divided by Opens. <b>(Healthcare Industry avg.= 4%)</b><br>
                  <b>Text Click Rate:</b> Text message links clicked divided by delivered texts.<br>`
          break
        case "unsubscribe-rate":
          desc = `<b>Unsubscribe Rate:</b> Unsubscribes divided by number of subscriptions. Target rate is < 1%.`
          break
        case "clicks":
          desc = `<b>Seg. #:</b>sequence of message in series<br>
                  <b>Title:</b> Series segment or Campaign title.<br>
                  <b>Total Clicks:</b> Count of clicks on link`
          break
        default:
          break
      }

      return desc
    },
  },
  filters: {
    nameToEndPoint(str) {
      return "/" + str.replace(/-/g, "_")
    },
    nameToTitle(str) {
      if (str === "clicks") {
        return "Top Links Clicked"
      }
      str = str.replace(/-/g, " ")
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }
}
</script>

<style scoped>
  .mx-datepicker { width: 100% }
</style>
