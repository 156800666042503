<template>
  <div class="flex flex-column mt1">
    <div class="desc-label" @click="show = !show">
      Description/info
      <img :src="questionImage"/>
    </div>
    <transition name="fade">
      <div class="desc-info mt2" v-if="show" v-html="description"></div>
    </transition>
  </div>
</template>

<script>
import questionImage from "../../images/question.svg";

export default {
  props: ["description"],
  data() {
    return {
      show: false,
      questionImage: questionImage,
    }
  },
}
</script>

<style scoped>
.desc-label {
  font-size: 12px;
  cursor: pointer;
  color: #555555;
}

.desc-info {
  font-size: 12px;
  color: #555555;
}
</style>
