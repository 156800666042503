<template>
  <div class="pa1 w-100 center">
    <div class="bg-moon-gray br-pill h1 overflow-y-hidden">
      <div v-if="step == 0" class="br-pill h1 shadow-1 pl5"></div>
      <div v-if="step == 1" class="bg-blue br-pill h1 shadow-1 w-50 pl5"></div>
      <div v-if="step == 2" class="bg-blue br-pill h1 shadow-1 w-100 pl5"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["step"]
}
</script>

<style>
</style>
