<template lang="html">
  <div class="settings-event-range-input flex">
    <date-picker
      class="mr3"
      format="YYYY-MM-DD"
      value-type="format"
      :editable="false"
      :clearable="false"
      v-model="value[0]"
    />

    <date-picker
      format="YYYY-MM-DD"
      value-type="format"
      :editable="false"
      :clearable="false"
      v-model="value[1]"
    />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

export default {
  props: ["series", "range"],
  components: { DatePicker },
  data() {
    return {
      value: this.range || [],
    }
  },
  mounted() {
    // set dates
  },
  watch: {
    value: function(val) {
      const range = {}
      range[this.series.to_param] = val
      this.$emit('update-ranges', range)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
