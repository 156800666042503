<template>
  <div class="mv4">
    <h3>Pathways and Campaigns</h3>
    <div v-if="!loaded" class="light-silver">
      Loading...
    </div>
    <div v-else class="">
      <div v-if="items.length" class="overflow-auto">
        <table class="w-100" cellspacing="0">
          <thead>
            <tr class="bb b--black-20">
              <th class="tl f6 bb b--black-20 pb1 pr2"></th>
              <th class="tl f6 bb b--black-20 pb1 pr2"></th>
              <th class="tl f6 bb b--black-20 pb1 pr2"></th>
            </tr>
          </thead>
          <tbody>
            <message-list-item
              v-for="(item, ix) in items"
              :key="ix"
              :item="item"
            />
          </tbody>
        </table>
      </div>
      <div v-else class="light-silver">
        <div v-if="error">
          Error loading messages
        </div>
        <div v-else>
          There are no messages for this subscriber.
        </div>
      </div>

      <a class="link blue pointer" @click="showMore" v-if="!last_page">
        Show more
      </a>
    </div>
  </div>
</template>

<script>
import MessageListItem from "./MessageListItem.vue";

export default {
  components: {
    MessageListItem,
  },
  props: ["hashid"],
  data() {
    return {
      error: "",
      loaded: false,
      items: [],
      page: 1,
      last_page: true,
    };
  },
  mounted() {
    this.$http
      .get(`/frontend/subscribers/${this.hashid}/messages`)
      .then((res) => {
        this.items = res.data.messages;
        this.page = res.data.current_page;
        this.last_page = res.data.last_page;
      })
      .catch((err) => (this.error = err.message))
      .finally(() => (this.loaded = true));
  },
  methods: {
    showMore() {
      this.last_page = true; // hide on click
      const nextPage = this.page + 1;
      this.$http
        .get(`/frontend/subscribers/${this.hashid}/messages?page=${nextPage}`)
        .then((res) => {
          this.items.push(...res.data.messages);
          this.page = res.data.current_page;
          this.last_page = res.data.last_page;
        })
        .catch((err) => (this.error = err.message))
        .finally(() => (this.loaded = true));
    },
  },
};
</script>

<style></style>
