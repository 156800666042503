<template lang="html">
  <div class="flex flex-wrap w-100">
    <div class="subscriptions w-50 pr2">
      <subscription-list :subscriber="subscriber" />
      <chat-bot-subscription-list v-if="chat_bots" :subscriber="subscriber" />
      <div v-if="secure_messaging" class="secure-messages">
        <a :href="`/secure_messages/${subscriber.to_param}`" class="blue link">Secure Messages</a>
      </div>
    </div>
    <div class="messages w-50 pl2">
      <message-list :hashid="subscriber.to_param" />
    </div>
    <unsubscribe-list :hashid="subscriber.to_param" />
  </div>
</template>

<script>
import SubscriptionList from "./SubscriptionList.vue";
import ChatBotSubscriptionList from "./ChatBotSubscriptionList.vue";
import MessageList from "./MessageList.vue";
import UnsubscribeList from "./UnsubscribeList.vue";

export default {
  props: ["subscriber", "secure_messaging", "chat_bots"],
  components: {
    SubscriptionList,
    ChatBotSubscriptionList,
    MessageList,
    UnsubscribeList,
  },
  data() {
    return {}
  },
}
</script>
