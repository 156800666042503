<template lang="html">
  <div class="settings-event-input flex">
    <input
      class="w-10"
      type="number"
      :name="series.to_param"
      v-model="value"
      max="730"
      min="-730"
    />
    <span class="pl2 pt1">
      days {{ preposition }}
      {{ series.event_name }}
    </span>
  </div>
</template>

<script>
export default {
  props: ["series", "offset"],
  data() {
    return {
      value: this.offset,
      preposition: "from",
    }
  },
  mounted() {
    if (!this.value) {
      let offset = {};
      offset[this.series.to_param] = 0;
      this.value = 0;
      this.$emit('update-offsets', offset);
    }
  },
  watch: {
    value: function(val) {
      let offset = {};
      offset[this.series.to_param] = val;
      this.$emit('update-offsets', offset);
      if (val < 0) { this.preposition = "before" };
      if (val == 0) { this.preposition = "from" };
      if (val > 0) { this.preposition = "after" };
    }
  }
}
</script>

<style lang="css" scoped>
</style>
