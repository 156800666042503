<template lang="html">
  <div class="w-100">
    <div v-if="error" class="pa2 br2 bg-washed-red dark-red mb2">
      {{ error }}
    </div>

    <div>
      <form
        class="flex flex-column w-100 mb3"
        @submit.prevent="submitForm"
      >
        <div v-if="series.endpoint_options.length > 1" class="mb2  mt1">
          <label for="deliver_to" class="db lh-copy">
            Deliver to <span class="red">*</span>
          </label>
          <select
            class="input-reset w-100 pa2 mt1 br2 b--black-40 ba"
            v-model="form_data.subscription.endpoint"
          >
            <option v-for="endpoint in series.endpoint_options" :key="endpoint" :value="endpoint">
              {{ endpoint | titleize }}
            </option>
          </select>
        </div>

        <div class="mb2 mt1">
          <label for="event_date" class="db lh-copy">
            {{ series.event_name }} <span class="red">*</span>
          </label>
          <date-picker
            input-class="input-reset w-100 pa2 mt1 br2 b--black-40 ba"
            format="YYYY-MM-DD"
            value-type="format"
            :editable="false"
            :clearable="false"
            required
            v-model="form_data.subscription.event_date"
          />
        </div>

        <signup-field
          v-for="field in signup_fields" :key="field.id"
          :field="field"
          class="mb2 mt1"
          v-model="form_data.subscription.details[field.id]"
        />

        <div class="mv2">
          <span class="red">*</span> required field
        </div>

        <div class="flex items-center">
          <input type="submit" value="Save" class="pa2 ph4 ba br2 b--gray bg-gray white pointer dim">
          <a class="link gray ml3" :href="`/subscribers/${this.subscriber_id}`">cancel</a>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import SignupField from "../signup_page/SignupField.vue"

export default {
  props: ["series", "signup_fields", "subscriber_id", "subscription", "details"],
  components: {
    DatePicker,
    SignupField
  },
  data() {
    return {
      error: null,
      form_data: {
        source: "website",
        series_id: this.series.to_param,
        subscriber_id: this.subscriber_id,
        subscription: {
          series_id: this.series.to_param,
          event_date: this.subscription.event_date,
          endpoint: this.subscription.endpoint || this.series.endpoint_options[0],
          details: this.details || {}
        }
      }
    }
  },
  methods: {
    submitForm() {
      const method = this.subscription.to_param ? "patch" : "post"
      const id = this.subscription.to_param ? `/${this.subscription.to_param}` : ""
      this.$http.request({ method: method, url: `/subscriptions${id}`, data: this.form_data })
        .then(response => {
          this.error = null
          window.location.href = `/subscribers/${this.subscriber_id}`
        })
        .catch(error => {
          this.error = error.response.data
        })
    }
  },
  filters: {
    titleize(text) {
      let words = text.replace("_", " ").split(" ")
      words = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      return words.join(" ")
    }
  }
}
</script>

<style lang="css" scoped>
</style>
