<template lang="html">
  <div class="flex w-100">

    <label class="w-10" :for="field.id">{{ field.label }}</label>
    <select
      v-model="selectedOption"
      :id="field.id"
      @change="updateDetail"
    >
      <option :value="''"></option>
      <option v-for="option in options" :value="option">
        {{ option }}
      </option>
    </select>

  </div>
</template>

<script>
export default {
  props: ["field", "selected"],
  data() {
    return {
      options: this.field.options_for_select,
      selectedOption: this.selected
    }
  },
  methods: {
    updateDetail() {
      if (this.selectedOption) {
        let detail = {}
        detail[this.field.id] = this.selectedOption
        this.$emit("update-details", detail)
      } else {
        this.$emit("delete-detail", this.field.id)
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
