<template>
  <div class="w-100">
    <div class="flex items-center pa2 br2 ba b--light-silver mb2 bg-light-gray">
      <div class="mr2">
        <a
          class="f7 pv1 ph2 bn br2 white pointer"
          :class="field.active ? 'bg-green mr2' : 'bg-gray'"
          @click="toggleValue('active')"
        >
          {{ field.active ? "Active" : "Inactive" }}
        </a>
      </div>
      <div class="w-40 mr4">
        <input
          class="w-100 pa2 br2 ba b--silver bg-white"
          :class="editField ? 'bg-white' : 'bg-transparent'"
          v-model.trim="field.label"
          :disabled="!editField"
        >
      </div>
      <div class="w-30 mr4">
        <input
          v-if="field.field_type == 'text'"
          class="w-100 pa2 br2 ba b--silver bg-white"
          placeholder="Text field"
        >
        <select
          v-if="field.field_type == 'dropdown' && !editField"
          class="w-100 pa2 br2 ba b--silver bg-white"
        >
          <option value="">Select one...</option>
          <option v-for="option in field.options_for_select" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
        <div v-if="field.field_type == 'dropdown' && editField">
          <div class="w-100 flex">
            <input
              class="w-100 pa1 br2 br--left ba b--silver bg-white"
              v-model="newOption"
              placeholder="New option"
              @keyup.enter="addOption"
            >
            <button
              class="pa1 ph2 bn br2 br--right white pointer bg-green"
              @click="addOption"
            >
              &check;
            </button>
          </div>
          <div class="w-100 flex flex-wrap">
            <div
              v-for="(option, index) in field.options_for_select" :key="option"
              class="br2 pv1 ph2 mt1 mr1 white bg-gray flex items-center nowrap draggable"
              draggable
              @dragstart="startDrag($event, index)"
              @drop="onDrop($event, index)"
              @dragover.prevent
              @dragenter.prevent
            >
              {{ option }}
              <div
                class="ml2 pointer hover-light-red dark-gray b"
                @click="removeOption(option)"
              >
                &times;
              </div>
            </div>
          </div>
        </div>

        <div v-if="field.field_type == 'date'">
          <date-picker
            input-class="w-100 pa2 br2 ba b--silver bg-white"
            format="YYYY-MM-DD"
            :value="new Date()"
            :clearable="false"
          />
          <small class="gray">Format: YYYY-MM-DD</small>
        </div>
      </div>
      <div class="mr2">
        <a
          class="link pointer f7"
          :class="field.required ? 'b' : ''"
          @click="toggleValue('required')"
        >
          {{ field.required ? "Required" : "Optional" }}
        </a>
      </div>
      <div class="mr2">
        <a
          class="link pointer f7"
          @click="toggleValue('internal')"
        >
          {{ field.internal ? "Private" : "Public" }}
        </a>
      </div>
      <div class="ml4">
        <a
          class="f7 pv1 ph2 bn br2 white pointer mr2"
          :class="editField ? 'bg-green' : 'bg-gray'"
          @click="editSaveClick"
        >
          {{ editField ? "Save" : "Edit" }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

export default {
  props: ["signup_field"],
  components: { DatePicker },
  data() {
    return {
      field: this.signup_field,
      editField: false,
      newOption: "",
      options: new Set(this.signup_field.options_for_select)
    }
  },
  methods: {
    toggleValue(value) {
      switch (value) {
        case "active":
          this.field.active = !this.field.active
          break
        case "required":
          this.field.required = !this.field.required
          break
        case "internal":
          this.field.internal = !this.field.internal
      }
      this.updateField()
    },
    addOption() {
      if (this.newOption) {
        this.options.add(this.newOption)
        this.field.options_for_select = Array.from(this.options)
        this.newOption = ""
      }
    },
    removeOption(option) {
      this.options.delete(option)
      this.field.options_for_select = Array.from(this.options)
    },
    editSaveClick() {
      if (this.editField) { this.updateField() }
      else { this.editField = !this.editField }
    },
    updateField() {
      this.editField = false
      this.newOption = ""
      let payload = { signup_field: this.field }
      this.$http
        .put(`/frontend/signup_fields/${this.field.to_param}`, {
          signup_field: {
            label: this.field.label,
            active: this.field.active,
            internal: this.field.internal,
            required: this.field.required,
            options_for_select: this.field.options_for_select
          }
        })
        .then(response => {
          this.field = response.data
        })
        .catch(error => {
          this.field = error.response.data
        })
    },
    startDrag(event, index) {
      event.dataTransfer.dropEffect = "move"
      event.dataTransfer.effectAllowed = "move"
      event.dataTransfer.setData("index", index)
    },
    onDrop (event, index) {
      const prevIndex = parseInt(event.dataTransfer.getData("index"))
      let arr = Array.from(this.options)
      arr.splice(index, 0, arr.splice(prevIndex, 1)[0])
      this.field.options_for_select = arr
      this.options = new Set(arr)
    }
  }
}
</script>

<style scoped>
  .mx-datepicker { width: 100% }
  .draggable { cursor: grab; }
</style>
