<template>
  <div class="mv4">
    <h3>Chat Bot Subscriptions</h3>
    <div v-if="!loaded" class="light-silver">
      Loading...
    </div>
    <div v-else>
      <div v-if="items.length">
        <ul class="list pl0">
          <chat-bot-subscription-list-item
            v-for="item in items"
            :key="item.id"
            :item="item"
            @unsubscribe="unsubscribe(item)"
          />
        </ul>
      </div>
      <div v-else class="light-silver">
        There are no chat bot subscriptions for this subscriber.
      </div>

      <div class="mt4">
        <a class="blue link pointer" @click="showMore" v-if="!last_page">
          Show more
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ChatBotSubscriptionListItem from "./ChatBotSubscriptionListItem.vue"

export default {
  components: {
    ChatBotSubscriptionListItem,
  },
  props: ["subscriber"],
  data() {
    return {
      loaded: false,
      error: "",
      items: [],
      page: 1,
      last_page: true,
    }
  },
  mounted() {
    // this.$http
    //   .get("/frontend/series")
    //   .then((res) => {
    //     this.series = res.data
    //     this.selectedSeries = this.series[0].to_param
    //   })

    this.$http
      .get(`/frontend/subscribers/${this.subscriber.to_param}/chat_bot_subscriptions`)
      .then((res) => {
        this.items = res.data.chat_bot_subscriptions
        this.page = res.data.current_page
        this.last_page = res.data.last_page
      })
      .catch((err) => (this.error = err.message))
      .finally(() => (this.loaded = true))
  },
  methods: {
    showMore() {
      // this.last_page = true // hide on click
      // const nextPage = this.page + 1
      // this.$http
      //   .get(
      //     `/frontend/subscribers/${this.subscriber.to_param}/subscriptions?page=${nextPage}`
      //   )
      //   .then((res) => {
      //     this.items.push(...res.data.subscriptions)
      //     this.page = res.data.current_page
      //     this.last_page = res.data.last_page
      //   })
      //   .catch((err) => (this.error = err.message))
      //   .finally(() => (this.loaded = true))
    },
    newSubscription() {
      // let url = `/subscriptions/new?series_id=${this.selectedSeries}&subscriber_id=${this.subscriber.to_param}`
      // window.location.href = url
    },
    unsubscribe(item) {
      this.$http.patch(`/frontend/chat_bot_subscriptions/${item.to_param}/unsubscribe`)
        .then((res) => {
          let index = this.items.indexOf(item)
          item.inactive = true
          item.status = "unsubscribed"
          this.items[index] = item
        })
    }
  }
}
</script>

<style></style>
