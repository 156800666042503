<template lang="html">
  <div class="confirm-event flex flex-column">
    <p>
      Send to subscribers based on a subscription status for these series:
    </p>
    <div
      class=""
      v-for="item in series"
      v-if="options.checkedSeries.includes(item.to_param)"
      :key="item.to_param"
    >
      <ul class="storyboards list pl0 mv0">
        <div v-for="(storyboard, ix) in item.storyboards" :key="ix">
          <li v-if="statuses.subscribed.includes(storyboard.to_param)">
            <b>{{ storyboard.name }}:</b> subscribed
          </li>
          <li v-if="statuses.graduated.includes(storyboard.to_param)">
            <b>{{ storyboard.name }}:</b> graduated
          </li>
        </div>
      </ul>

      <p>Send to subscribers registered with matching signup field values:</p>
      <p v-html="humanizedFilter(item)"></p>
    </div>

    <div class="mt3" v-if="options.scheduled == 'future'">
      Scheduled to send on {{ options.sendoutDate }}.
    </div>
    <div class="mt3" v-else>
      Scheduled to send now.
    </div>
  </div>
</template>

<script>
export default {
  props: ["series", "options"],
  data() {
    return {
      statuses: this.options.statuses || {},
      details: this.options.details || {}
    }
  },
  methods: {
    humanizedFilter(series) {
      let details = this.details[series.to_param]
      if (!details) { return "N/A" }
      let text = ""
      Object.keys(details).forEach(function(id) {
        let label = series.signup_fields.find(field => field.id === parseInt(id)).label
        text += `${label}: ${details[id]}<br>`
      })
      return text
    }
  }
}
</script>

<style lang="css" scoped>
</style>
