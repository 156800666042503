<template>
  <li class="pt2 bt b--black-20" :class="item.status == 'archived' ? 'mb3 moon-gray' : 'mb3'">
    <div class="">
      <span class="b">{{ item.chat_bot.name }}</span>
    </div>
    <div>
      Due Date or Child's Birthday: {{ item.event_date }}
    </div>
    <div class="mb2">
      <span :class="item.status">{{ item.status }}</span>
      / phone_number
      <a
        v-if="!this.item.inactive"
        class="ml2 pv0 ph2 br2 bg-light-red black f7 pointer"
        @click="unsubscribe"
      >
        unsubscribe
      </a>
    </div>
  </li>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {}
  },
  mounted() {},
  methods: {
    unsubscribe() {
      if (confirm("This will permanently unsubscribe this chat bot subscription. Are you sure?")) {
        this.$emit("unsubscribe")
      }
    }
  },
}
</script>

<style scoped>
  .unsubscribed { color: #FF725C; }
  .subscribed { color: #333333; }
  .graduated { color: #999999; }
</style>
