<template lang="html">
  <div class="flex flex-column w-100">
    <progress-bar :step="this.step"></progress-bar>
    <component
      class="bg-white ph4 pb4 mt2 ba b--near-white shadow-4 br2"
      :is="stepComponent"
      :baseKind="kind"
      :options="options"
      :series="series"
      :all_hospitals="all_hospitals"
      :campaign="campaign"
      @change-kind="kind = $event"
      @change-step="step = $event"
      @add-options="addOptions($event)"
      @reset-options="options = {}"
    />

    <div
      class="mt4 bg-dark-gray gold pa4 ba br2 b--dark-gray flex flex-column"
      v-if="devEnv"
    >
      <div class="">
        Development Mode (debugger)
      </div>
      <div class="mt2">
        {{ kind }}
      </div>
      <div class="mt2">
        {{ options }}
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar.vue";
import Audience from "./Audience.vue";
import Settings from "./Settings.vue";
import Confirm from "./Confirm.vue";

export default {
  props: ["campaign", "series", "all_hospitals"],
  components: {
    ProgressBar,
    Audience,
    Settings,
    Confirm,
  },
  data() {
    return {
      step: 0,
      steps: ["Audience", "Settings", "Confirm"],
      kind: "Status",
      options: {},
      devEnv: process.env.NODE_ENV === 'development',
    }
  },
  computed: {
    stepComponent: function() {
      return this.steps[this.step];
    },
  },
  methods: {
    addOptions: function(obj) {
      let updatedOptions = {
        ...this.options,
        ...obj,
      }
      this.options = updatedOptions;
    }
  }
}
</script>

<style lang="css" scoped>
</style>
