<template lang="html">
  <div class="w-100">
    <div class="flex items-end">
      <div class="w-20 mr4">
        <label for="start-date" class="db mb1">Start date</label>
        <date-picker
          input-class="input-reset w-100 pa2 br2 b--black-40 ba"
          format="YYYY-MM-DD"
          value-type="format"
          :editable="false"
          :clearable="false"
          :disabled-date="fromToday"
          required
          v-model="start_date"
        />
      </div>
      <div class="w-20 mr4">
        <label for="end-date" class="db mb1">End date</label>
        <date-picker
          input-class="input-reset w-100 pa2 br2 b--black-40 ba"
          format="YYYY-MM-DD"
          value-type="format"
          :editable="false"
          :clearable="false"
          :disabled-date="fromToday"
          required
          v-model="end_date"
        />
      </div>

      <button
        @click="update"
        class="pa2 ph4 ba br2 b--gray bg-gray white pointer dim"
      >
        Update
      </button>
    </div>

    <div v-if="Object.keys(data).length === 0">
      <p v-if="loading" class="gray">Loading...</p>
      <p v-else>No metrics data available for this chat bot</p>
    </div>

    <div v-else>
      <div v-if="Object.keys(data.population_data).length">
        <h3>Population stats</h3>
        <table class="w-100 center" cellspacing="0">
          <thead>
            <tr>
              <th class="fw6 bb b--black-20 tl pb2 pr3">New</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Active</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Graduated</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Unsubscribed</th>
            </tr>
          </thead>
          <tbody class="lh-copy">
            <tr>
              <td class="pv2 pr3 bb b--black-20">{{ data.population_data.new }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ data.population_data.active }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ data.population_data.graduated }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ data.population_data.unsubscribed }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="Object.keys(data.message_data).length">
        <h3>Message stats</h3>
        <table class="w-100 center" cellspacing="0">
          <thead>
            <tr>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Sent</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Delivered</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Tapped</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Responses</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Failed</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Errors</th>
            </tr>
          </thead>
          <tbody class="lh-copy">
            <tr>
              <td class="pv2 pr3 bb b--black-20">{{ data.message_data.sent }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ data.message_data.delivered }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ data.message_data.clicked }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ data.message_data.responses }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ data.message_data.failed }}</td>
              <td class="pv2 pr3 bb b--black-20">
                <div v-for="error in Object.keys(data.message_data.errors)" :key="error">
                  {{ error }}: {{ data.message_data.errors[error] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="Object.keys(data.unexpected_responses).length">
        <h3>Unexpected responses</h3>
        <table class="w-100 center" cellspacing="0">
          <thead>
            <tr>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Patient ID</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Name</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Phone number</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Response</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Date</th>
            </tr>
          </thead>
          <tbody class="lh-copy">
            <tr v-for="(response, i) in data.unexpected_responses" :key="i">
              <td class="pv2 pr3 bb b--black-20">{{ response[0] }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ response[1] }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ response[2] }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ response[5] }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ response[6] }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt3">
        <a class="blue link pointer" @click="download_data">download data</a>
      </div>
    </div>

  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

export default {
  components: { DatePicker },
  props: ["chatBotId"],
  data() {
    return {
      loading: true,
      data: {},
      start_date: "",
      end_date: "",
      account_hospital_id: ""
    }
  },
  mounted() {
    let today = new Date()
    this.start_date = new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString().split("T")[0]
    this.end_date = new Date(today.getFullYear(), today.getMonth(), 0).toISOString().split("T")[0]
    this.update()
  },
  computed: {
    params: function() {
      return {
        start_date: this.start_date,
        end_date: this.end_date
      }
    }
  },
  methods: {
    fromToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0))
    },
    update() {
      this.loading = true
      this.data = {}
      // FIXME: use the frontend controller pattern
      this.$http.get(`chat_bots/${this.chatBotId}/metrics`, { params: this.params })
        .then(response => {
          this.data = response.data
          this.loading = false
        })
    },
    displayRate(value) {
      return value ? value.toFixed(2) : ""
    },
    download_data() {
      let data = this.data.csv_data.data
      data = "data:text/csv;charset=utf-8," + encodeURIComponent(data)
      let downloadLink = document.createElement("a")
      downloadLink.style.visibility = "hidden"
      downloadLink.setAttribute("href", data)
      downloadLink.setAttribute("download", this.data.csv_data.filename)
      document.body.appendChild(downloadLink) // required for firefox
      downloadLink.click()
      downloadLink.remove()
    }
  }
}
</script>

<style lang="css" scoped>
</style>
