<template lang="html">
  <div>
    <p>
      <b>Hospitals</b>
      (subscribers must be associated to any of the selected hospitals)
    </p>

    <div
      v-for="hospital in all_hospitals"
      :key="hospital.id"
    >
      <input
        type="checkbox"
        :value="hospital.id"
        v-model="hospitals"
        @change="updateHospitals"
      >
      <label for="hospital.name">{{ hospital.name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: ["all_hospitals", "options"],
  components: {},
  data() {
    return {
      hospitals: this.options.hospitals || []
    }
  },
  methods: {
    updateHospitals() {
      this.$emit("add-options", { hospitals: this.hospitals })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
