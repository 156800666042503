<template lang="html">
  <div class="">
    <article
      class="br2 hidden ba b--black-20 mv1"
      :class="{'tr': message.author_type !== 'Subscriber'}"
    >
      <div class="br2 br--top mv0 pv1 ph2 bg-light-gray">
        {{ message.author_name }} - {{ new Date(message.created_at).toLocaleString() }}
      </div>
      <div class="pv1 ph2 br2 br--bottom bt b--black-20 bg-white">
        <div class="lh-copy">
          {{ message.text }}
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  props: ["message"]
}
</script>

<style lang="css" scoped>
</style>
