<template>
  <div class="mt4">
    <div v-if="items.length">
      <h3>Unsubscribes</h3>
      <div v-for="item in items" :key="item.id" class="mb2">
        <div>
          <b>{{ item.created_at.split("T")[0] }}</b>
          from the {{ item.message.project.name }}
          - Segment {{ item.message.sequence }}
          message.
        </div>
        <div v-if="item.feedback">
          <b>Feedback:</b>
          <i>{{ item.feedback.join(", ") }}</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["hashid"],
  data() {
    return {
      error: "",
      loaded: false,
      items: [],
      page: 1,
      last_page: true,
    };
  },
  mounted() {
    this.$http
      .get(`/frontend/subscribers/${this.hashid}/unsubscribes`)
      .then((res) => {
        this.items = res.data.unsubscribes;
        this.page = res.data.current_page;
        this.last_page = res.data.last_page;
      })
      .catch((err) => (this.error = err.message))
      .finally(() => (this.loaded = true));
  },
}
</script>
