<template lang="html">
  <div class="confirm-event flex flex-column">
    <p>
      Send to subscribers before or after a storyboard message:
    </p>
    <div
      class=""
      v-for="item in series"
      v-if="options.checkedSeries.includes(item.to_param)"
      :key="item.to_param"
    >
      <b>{{ item.name }}:</b>
      {{ Math.abs(days(offsets[item.to_param])) || 0 }}
      {{ (Math.abs(days(offsets[item.to_param])) == 1) ? "day" : "days" }}
      {{ (days(offsets[item.to_param]) >= 0) ? "after" : "before" }}
      <b>{{ titles[item.to_param] }}</b>

      <p>Send to subscribers registered with matching signup field values:</p>
      <p v-html="humanizedFilter(item)"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["series", "options"],
  data() {
    return {
      titles: Object.fromEntries(this.options.checkedSeries.map((x) => { return [x, ""] })),
      offsets: this.options.offsets || {},
      details: this.options.details || {}
    }
  },
  mounted() {
    this.options.checkedSeries.forEach((id) => {
      let segment_id = this.segmentId(this.offsets[id])
      this.$http
        .get(`/frontend/segments/${segment_id}`)
        .then(res => {
          this.titles[id] = res.data.title
        })
        .catch(err => {
          this.titles[id] = "Error retreiving title"
        })
    })
  },
  methods: {
    segmentId(value) {
      return Object.keys(value)[0]
    },
    days(value) {
      return value[this.segmentId(value)]
    },
    humanizedFilter(series) {
      let details = this.details[series.to_param]
      if (!details) { return "N/A" }
      let text = ""
      Object.keys(details).forEach(function(id) {
        let label = series.signup_fields.find(field => field.id === parseInt(id)).label
        text += `${label}: ${details[id]}<br>`
      })
      return text
    }
  }
}
</script>

<style lang="css" scoped>
</style>
