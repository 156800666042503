<template />

<script>
  export default {
    props: [
      "endPoint",
      "seriesId",
      "startDate",
      "endDate",
      "hospitalId",
      "updateTrigger"
    ],
    data() {
      return {
        data: {},
        loaded: false,
        error: null,
        projectInfo: ""
      }
    },
    mounted() {
      this.$emit("mounted")
    },
    methods: {
      updateData() {
        this.loaded = false
        const path = this.endPoint +
                     "?series_id=" + this.seriesId +
                     "&start_date=" + this.startDate +
                     "&end_date=" + this.endDate +
                     "&account_hospital_id=" + this.hospitalId +
                     "&project_id=" + this.projectId +
                     "&project_type=" + this.projectType
        this.$http.get(path)
          .then(res => { this.data = res.data })
          .catch(err => {
            this.data = {}
            this.error = `Error loading chart: ${err.message}`
          })
          .finally(() => { this.loaded = true })
      }
    },
    computed: {
      projectId() { return this.projectInfo.split(":")[0] || "" },
      projectType() { return this.projectInfo.split(":")[1] || "" }
    },
    watch: {
      updateTrigger: { handler: "updateData" },
      projectInfo: { handler: "updateData" }
    }
  }
</script>

<style />
