<template lang="html">
  <div class="settings-detail-series" v-if="series.signup_fields.length">
    <p>
      <b>Signup field filters</b>
      (subscribers have to match all fields selected)
    </p>
    <settings-detail-select
      v-for="field in series.signup_fields"
      v-if="field.field_type == 'dropdown'"
      :key="field.id"
      :field="field"
      :selected="details[field.id]"
      @update-details="addDetails($event)"
      @delete-detail="deleteDetail($event)"
    />
  </div>
</template>

<script>
import SettingsDetailSelect from "./SettingsDetailSelect.vue";

export default {
  props: ["series", "options"],
  components: { SettingsDetailSelect },
  data() {
    return {
      details: (this.options.details || {})[this.series.to_param] || {}
    }
  },
  methods: {
    addDetails: function(obj) {
      this.details = {
        ...this.details,
        ...obj
      };
      let seriesDetails = {};
      seriesDetails[this.series.to_param] = this.details;
      this.$emit("update-details", seriesDetails);
    },
    deleteDetail: function(field_id) {
      delete this.details[field_id];
      let seriesDetails = {};
      seriesDetails[this.series.to_param] = this.details;
      this.$emit("update-details", seriesDetails);
    }
  }
}
</script>

<style lang="css" scoped>
</style>
