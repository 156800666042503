<template lang="html">
  <div class="settings-subscription">

    <div
      class="flex flex-column mb3 bb b--gray pb3"
      v-for="item in series"
      v-if="options.checkedSeries.includes(item.to_param)"
      :key="item.to_param"
    >
      <h4>{{ item.name }}</h4>

      <settings-subscription-input
        :series="item"
        :offset="offsets[item.to_param]"
        @update-offsets="addOptions($event)"
      />

      <settings-detail-series
        :series="item"
        :options="options"
        @update-details="updateDetails($event)"
      />
    </div>

  </div>
</template>

<script>
import SettingsSubscriptionInput from "./SettingsSubscriptionInput.vue"
import SettingsDetailSeries from "./SettingsDetailSeries.vue"

export default {
  props: ["series", "options"],
  components: { SettingsSubscriptionInput, SettingsDetailSeries },
  data() {
    return {
      offsets: this.options.offsets || {},
      details: this.options.details || {}
    }
  },
  methods: {
    addOptions: function(obj) {
      this.offsets = {
        ...this.offsets,
        ...obj
      };
      this.$emit("add-options", { offsets: this.offsets });
    },
    updateDetails: function(obj) {
      this.details = {
        ...this.details,
        ...obj
      };
      this.$emit("add-options", { details: this.details });
    }
  }
}
</script>

<style lang="css" scoped>
</style>
