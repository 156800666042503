import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue/dist/vue.esm";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import * as RailsUJS from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import axios from "axios";
import DashboardBase from "../components/dashboard/Base.vue";
import SubscriberBase from "../components/subscriber/Base.vue";
import TextMessageBase from "../components/text_message/Base.vue";
import TextMessageSearch from "../components/text_message/Search.vue";
import SignupFieldsBase from "../components/signup_fields/Base.vue";
import CampaignScheduleBase from "../components/campaign_schedule/Base.vue";
import SecureMessageList from "../components/subscriber/SecureMessageList.vue";
import SubscriptionForm from "../components/subscription/Form.vue";
import ProjectMetrics from "../components/metrics/Base.vue";
import ChatBotMetrics from "../components/metrics/ChatBot.vue";
import "../src/application.css";

axios.defaults.timeout = 120000

if (process.env.NODE_ENV == "production") {
  Sentry.init({
    dsn: "https://eb48c24019664b6a87a6b2b5be67cc1e@o122957.ingest.sentry.io/5269713",
    integrations: [new VueIntegration({Vue, attachProps: true})],
    environment: process.env.NODE_ENV,
  });
}

RailsUJS.start();
ActiveStorage.start();
Vue.use(Chartkick.use(Chart));

Vue.component("dashboard-base", DashboardBase);
Vue.component("subscriber-base", SubscriberBase);
Vue.component("text-message-base", TextMessageBase);
Vue.component("text-message-search", TextMessageSearch);
Vue.component("signup-fields-base", SignupFieldsBase);
Vue.component("campaign-schedule-base", CampaignScheduleBase);
Vue.component("secure-message-list", SecureMessageList);
Vue.component("subscription-form", SubscriptionForm);
Vue.component("project-metrics", ProjectMetrics);
Vue.component("chat-bot-metrics", ChatBotMetrics);
Vue.prototype.$http = axios;
Vue.prototype.$sentry = Sentry;

document.addEventListener("DOMContentLoaded", () => {
  const csrfToken = document.querySelector("meta[name=csrf-token]");
  if (csrfToken && csrfToken.content) {
    Vue.prototype.$http.defaults.headers.common["X-CSRF-Token"] =
      csrfToken.content;
  }

  // eslint-disable-next-line no-unused-vars
  const vm = new Vue({
    el: '[data-behavior="vue"]',
  });
});
