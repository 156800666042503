<template>
  <div class="w-100 mt4">

    <div class="flex items-center mb3">
      <a class="pv1 ph3 bn br2 white pointer mr2 bg-gray" @click="addField('text')">Add Text Field</a>
      <a class="pv1 ph3 bn br2 white pointer mr2 bg-gray" @click="addField('dropdown')">Add Drop-down Field</a>
      <a class="pv1 ph3 bn br2 white pointer bg-gray" @click="addField('date')">Add Date Field</a>
    </div>

    <signup-field v-for="field in fields" :key="field.id" :signup_field="field" />
  </div>
</template>

<script>
  import SignupField from "./SignupField.vue";

  export default {
    props: ["series", "signup_fields"],
    components: {
      SignupField
    },
    data() {
      return {
        fields: this.signup_fields,
      }
    },
    methods: {
      addField(type) {
        this.$http
          .post("/frontend/signup_fields", {
            signup_field: {
              field_type: type,
              series_id: this.series.id
            }
          })
          .then(res => {
            this.fields.push(res.data);
          })
          .catch(err => {
            // FIXME: log to sentry
          })
      }
    }
  }
</script>

<style scoped>
</style>
