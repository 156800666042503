<template>
  <div class="w-100">
    <div v-if="loaded">
      <select
        class="w-100 mb3 f6 h2 ph1 br2 bg-white b--black-20 border-box"
        v-model="projectInfo"
      >
        <option selected value="">All pathways</option>
        <option v-for="storyboard in data.storyboards" :value="storyboard.id + ':Storyboard'">
          {{ storyboard.name }}
        </option>
        <option value="0:Campaign">All campaigns</option>
        <option value="0:Library">All libraries</option>
      </select>
      <div v-if="gotData">
        <div class="overflow-auto" style="max-height:575px; overflow-wrap: anywhere;">
          <table class="f6 w-100 center" cellspacing="0">
            <thead>
              <tr>
                <th class="fw6 bb b--black-20 tl pb3 pr3 nowrap">Seg #</th>
                <th class="fw6 bb b--black-20 tl pb3 pr3 nowrap">Title</th>
                <th class="fw6 bb b--black-20 tl pb3 pr3">URL</th>
                <th class="fw6 bb b--black-20 tl pb3 pr3 nowrap">Total clicks</th>
              </tr>
            </thead>
            <tbody class="lh-copy">
              <tr v-for="click in data.chartData">
                <td class="pv2 pr3 bb b--black-20">{{ click[0] }}</td>
                <td class="pv2 pr3 bb b--black-20 nowrap">{{ click[1] }}</td>
                <td class="pv2 pr3 bb b--black-20" v-html="link(click[2])"></td>
                <td class="pv2 pr3 bb b--black-20">{{ click[3] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="center pv4 f6 light-silver tc">
        <div v-if="error">{{ error }}</div>
        <div v-else>No clickstream data for the selected time frame and/or projects</div>
      </div>
    </div>
    <div v-else>
      <div class="center pv4 f6 light-silver tc">
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
  import ChartBase from './ChartBase.vue';

  export default {
    extends: ChartBase,
    methods: {
      link(str) {
        if (str.includes("http")) {
          return `<a class="blue link" target="_blank" href="${str}">${str}</a>`
        } else { return str }
      }
    },
    computed: {
      gotData: function() {
        return Object.keys(this.data.chartData).length > 0
      }
    }
  }
</script>

<style></style>
