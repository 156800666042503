<template lang="html">
  <div>
    <h3>Secure Messages with <a :href="`/subscribers/${subscriber.to_param}`" class="blue link">{{ subscriber.name }}</a></h3>
    <div v-if="user" class="mb3">
      <label for="new_message" class="db lh-copy">
        New secure message
      </label>
      <textarea
        rows="4"
        class="input-reset w-100 pa2 mt1 br2 b--black-40 ba"
        v-model="newMessage"
      />
      <button class="input-reset mt1 w4 pa2 bn bg-gray white br2" @click="sendMessage">Send</button>
      <a class="ml2 link gray pointer" @click="newMessage = null">Clear</a>
    </div>
    <div v-if="messages.length > 0">
      <secure-message-list-item
        v-for="message in messages" :key="message.id"
        :message="message"
      />
    </div>
    <div v-else class="gray">
      No messages found
    </div>
  </div>
</template>

<script>
import SecureMessageListItem from "./SecureMessageListItem.vue"

export default {
  components: { SecureMessageListItem },
  props: ["subscriber", "user"],
  data() {
    return {
      messages: [],
      newMessage: null,
    }
  },
  mounted() {
    this.$http.get(`/frontend/subscribers/${this.subscriber.to_param}/secure_messages`)
      .then(response => {
        this.messages = response.data.secure_messages
      })
  },
  methods: {
    sendMessage() {
      this.$http.post(
        `/frontend/secure_messages`,
        {
          secure_message: {
            subscriber_id: this.subscriber.id,
            author_id: this.user.id,
            author_type: "Customer",
            text: this.newMessage
          }
        }
      ).then(response => {
        this.messages.push(response.data.secure_message)
        this.newMessage = null
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
