<template>
  <div class="w-100">
    <div v-if="loaded">
      <div v-if="gotData" class="flex items-start">
        <div
          class="ph2 w-20"
          :class="`w-${100 / Object.keys(data).length}`"
          v-for="(val, key) in data"
        >
          <section class="hidden ba br2 b--moon-gray">
            <div class="bg-blue white f3 mv0 pv2 ph3 tc">
              {{ val.rate == -1 ? "--" : val.rate + "%" }}
            </div>
            <div class="ph3 pv3 bt bg-white b--moon-gray f6">
              {{ descriptions[key] }} <br>
            </div>
            <div class="bg-white gray mv0 pv2 ph3 f6">
              from {{ val.responders }} responders
            </div>
          </section>
        </div>
      </div>
      <div v-else class="center pv4 f6 light-silver tc">
        <div v-if="error">{{ error }}</div>
        <div v-else>
          Patient Satisfaction<br>
          No responses for any survey metric in the selected time frame.
        </div>
      </div>
    </div>
    <div v-else class="center pv4 f6 light-silver tc">Loading...</div>
  </div>
</template>

<script>
  import ChartBase from './ChartBase.vue';

  export default {
    extends: ChartBase,
    data() {
      return {
        descriptions: {
          self: "are able to take better care of themselves",
          child: "are able to take better care of their child",
          confidence: "feel more confident to manage their care",
          prepared: "feel better prepared for their appointments",
          connection: "feel more connected to their hospital"
        }
      }
    },
    computed: {
      gotData: function() {
        return Object.keys(this.data).length > 0
      }
    }
  }
</script>

<style></style>
