<template>
  <tr :class="item.status">
    <td class="f6 pb1 pr2">{{ item.date }}</td>
    <td class="f6 pb1 pr2">{{ item.project_segment_name }}</td>
    <td class="f6 pb1 pr2">{{ item.status }}</td>
  </tr>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style lang="css" scoped>
.delivered {
  background-color: #fcf8e3;
}
.opened {
  background-color: #d9edf7;
}
.clicked {
  background-color: #dff0d8;
}
.bounced {
  background-color: #f2dede;
}
.failed {
  background-color: #f2dede;
}
.undelivered {
  background-color: #f2dede;
}
</style>
