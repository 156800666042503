<template>
  <div class="w-100">
    <div v-if="loaded">
      <div v-if="gotData" class="w-100 flex flex-wrap">
        <div class="w-100 pb3">
          <span class="b">Total interactions:</span>
          {{ data.total_interactions.toLocaleString() }}
        </div>
        <div class="w-25 pr3">
          <label class="b">Email messages</label>
          <table class="w-100 collapse mt2">
            <tr>
              <td class="pv2 bt b--moon-gray">Delivered</td>
              <td class="pv2 bt b--moon-gray">{{ data.message_interactions.email.delivered.toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="pv2 bt b--moon-gray">Opened</td>
              <td class="pv2 bt b--moon-gray">{{ data.message_interactions.email.opened.toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="pv2 bt b--moon-gray">Clicked</td>
              <td class="pv2 bt b--moon-gray">{{ data.message_interactions.email.clicked.toLocaleString() }}</td>
            </tr>
          </table>
        </div>
        <div v-if="Object.keys(data.message_interactions.sms).length > 0" class="w-25 pr3">
          <label class="b">Text messages</label>
          <table class="w-100 collapse mt2">
            <tr>
              <td class="pv2 bt b--moon-gray">Delivered</td>
              <td class="pv2 bt b--moon-gray">{{ data.message_interactions.sms.delivered.toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="pv2 bt b--moon-gray">Clicked</td>
              <td class="pv2 bt b--moon-gray">{{ data.message_interactions.sms.clicked.toLocaleString() }}</td>
            </tr>
          </table>
        </div>
        <div class="w-25 pr3">
          <label class="b">Subscriber Responses</label>
          <table class="w-100 collapse mt2">
            <tr>
              <td class="pv2 bt b--moon-gray">Survey</td>
              <td class="pv2 bt b--moon-gray">{{ data.response_interactions.survey.toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="pv2 bt b--moon-gray">Responsive feedback</td>
              <td class="pv2 bt b--moon-gray">{{ data.response_interactions.respfb.toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="pv2 bt b--moon-gray">Vote</td>
              <td class="pv2 bt b--moon-gray">{{ data.response_interactions.vote.toLocaleString() }}</td>
            </tr>
          </table>
        </div>
        <div class="w-25 pr3">
          <label class="b">Web</label>
          <table class="w-100 collapse mt2">
            <tr>
              <td class="pv2 bt b--moon-gray">Secured messages</td>
              <td class="pv2 bt b--moon-gray">{{ data.secure_messages_count.toLocaleString() }}</td>
            </tr>
            <tr>
              <td class="pv2 bt b--moon-gray">Clicked links</td>
              <td class="pv2 bt b--moon-gray">{{ data.clicked_links_count.toLocaleString() }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div v-else class="center pv4 f6 light-silver tc">
        <div v-if="error">{{ error }}</div>
        <div v-else>No engagement data</div>
      </div>
    </div>
    <div v-else class="center pv4 f6 light-silver tc">Loading...</div>
  </div>
</template>

<script>
  import ChartBase from './ChartBase.vue';

  export default {
    extends: ChartBase,
    computed: {
      gotData: function() {
        return Object.keys(this.data).length > 0
      }
    }
  }
</script>

<style></style>
