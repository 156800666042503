<template>
  <div class="w-100">
    <div v-if="loaded">
      <select
        v-if="data.storyboards && data.storyboards.length > 1"
        class="w-100 mb3 f6 h2 ph1 br2 bg-white b--black-20 border-box"
        v-model="projectInfo"
      >
        <option selected value="">All messages</option>
        <option v-for="storyboard in data.storyboards" :value="storyboard.id + ':Storyboard'">
          {{ storyboard.name }}
        </option>
        <option selected value="campaigns">All campaigns</option>
      </select>
      <line-chart
        :data="data.chartData"
        :curve="false"
        ytitle="# People Reached"
        thousands=","
      />
    </div>
    <div v-else class="center pv4 f6 light-silver tc">Loading...</div>
  </div>
</template>

<script>
  import ChartBase from './ChartBase.vue';

  export default {
    extends: ChartBase
  }
</script>

<style></style>
