<template>
  <div class="flex flex-column w-100">
    <div>
      Text messages with
      <a class="blue link" :href="url">
        {{ subscriber.given_name }} {{ subscriber.family_name }}
      </a>
      <small
        v-if="subscriber.kind != 'patient'"
        class="silver"
      >
        {{ subscriber.kind }}
      </small>
    </div>

    <div class="pv2 mv2 w-100">
      <div v-for="item in list" :key="item.id">
        <div class="w-80 mb2" :class="{ 'ml-auto': item.author_type !== 'Subscriber' }">
          <div class="br3 ph3 pv2" :class="item.author_type === 'Subscriber' ? 'bg-moon-gray black' : 'bg-blue white'">
            {{ item.text }}
          </div>
          <div class="f6 black-40" :class="statusClass(item)">
            {{ item.status }}
            {{ new Date(item.created_at).toLocaleString("en-US", { timeZone }) }}
          </div>
        </div>
      </div>
    </div>

    <textarea
      class="w-100 pa3 br2 b--black-20 ba mb2"
      style="resize: none;"
      v-model="text"
    />
    <div class="ml-auto">
      <button
        class="br2 bg-blue white ph3 pv1 bn dim pointer"
        :disabled="disabled"
        @click="sendTextMessage()"
      >
        Send
      </button>

      <div class="red">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['current_user', 'subscriber', 'text_messages'],
  data() {
    return {
      url: `/subscribers/${this.subscriber.to_param}`,
      list: this.text_messages,
      text: '',
      disabled: true,
      error: '',
      timeZone: this.current_user.zoneinfo,
      timer: null
    }
  },
  mounted() {
    this.timer = setInterval(this.getTextMessages, 10000)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
  watch: {
    text: function (val) {
      if (val.length) {
        this.error = ''
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    list: function (val) {
      console.log(val)
    }
  },
  methods: {
    sendTextMessage() {
      this.disabled = true

      this.$http.post(
        `/frontend/text_messages`,
        {
          subscriber_id: this.subscriber.to_param,
          text: this.text
        }
        ).then(res => this.list.push(res.data))
        .catch(err => this.error = err.message)
        .finally(() => this.text = '')
    },
    getTextMessages() {
      this.$http.get(`/frontend/text_messages/${this.subscriber.to_param}`)
        .then(res => this.list = res.data)
        .catch(err => console.error(err))
        .finally(() => this.disabled = false)
    },
    statusClass(item) {
      let color = item.status === "failed" ? "light-red" : "black-40"
      let textAlign = item.author_type === 'Subscriber' ? "tl" : "tr"
      return `${color} ${textAlign}`
    }
  }
}
</script>

<style scoped>
</style>
