<template lang="html">
  <div class="settings-segment-series flex flex-column">
    <h4>{{ series.name }}</h4>

    <div class="flex">
      <input
        class="w-10"
        type="number"
        :name="series.to_param"
        v-model="days"
        max="730"
        min="-730"
        @input="updateOffset"
      />
      <div class="pl2 pt1">
        days {{ preposition }}

        <select v-model="segment_id" @change="updateOffset">
          <option v-for="item in segments" :value="item.id">
            {{ item.title }}
          </option>
        </select>
      </div>

      <div class="red pl2 pt2" v-if="error">{{ error }}</div>
    </div>

    <settings-detail-series
      :series="series"
      :options="options"
      @update-details="updateDetails($event)"
    />
  </div>
</template>

<script>
import SettingsDetailSeries from "./SettingsDetailSeries.vue";

export default {
  props: ["series", "options", "offset"],
  components: { SettingsDetailSeries },
  data() {
    return {
      segments: [],
      details: this.options.details || {},
      days: 0,
      segment_id: null,
      error: null,
    }
  },
  mounted() {
    this.$http
      .get(`/frontend/series/${this.series.to_param}/segments`)
      .then(res => {
        this.segments = res.data;
        this.segment_id = this.segments[0].id
        this.updateOffset()
      })
      .catch(err => {
        this.error = `An error occured while requesting segments for ${this.series.name}`;
      });
  },
  methods: {
    updateDetails: function(obj) {
      this.details = {
        ...this.details,
        ...obj,
      };
      this.$emit("update-details", { details: this.details });
    },
    updateOffset() {
      let value = {}
      value[this.segment_id] = this.days
      let offset = {}
      offset[this.series.to_param] = value
      this.$emit('update-offsets', offset)
    }
  },
  computed: {
    preposition: function() {
      if (this.days < 0) { return "before" }
      else if (this.days > 0) { return "after" }
      else { return "from" }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
