<template lang="html">
  <div class="settings-segment flex flex-column">
    <settings-segment-series
      class="mb3 bb b--gray pb3"
      v-for="item in series"
      v-if="options.checkedSeries.includes(item.to_param)"
      :key="item.to_param"
      :series="item"
      :options="options"
      :offset="offsets[item.to_param]"
      @update-offsets="updateOffsets($event)"
      @update-details="updateDetails($event)"
    />
  </div>
</template>

<script>
import SettingsSegmentSeries from "./SettingsSegmentSeries.vue";

export default {
  props: ["series", "options"],
  components: { SettingsSegmentSeries },
  data() {
    return {
      offsets: this.options.offsets || {},
      details: this.options.details || {},
    }
  },
  methods: {
    updateOffsets: function(obj) {
      this.offsets = {
        ...this.offsets,
        ...obj,
      };
      this.$emit("add-options", { offsets: this.offsets });
    },
    updateDetails: function(obj) {
      this.$emit("add-options", obj)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
