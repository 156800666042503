<template lang="html">
  <div class="settings-event-range">

    <div
      class="flex flex-column mb3 bb b--gray pb3"
      v-for="item in series"
      v-if="options.checkedSeries.includes(item.to_param)"
      :key="item.to_param"
    >
      <h4>{{ item.name }}</h4>

      <settings-event-range-input
        :series="item"
        :range="ranges[item.to_param] || []"
        @update-ranges="addOptions($event)"
      />

      <settings-detail-series
        :series="item"
        :options="options"
        @update-details="updateDetails($event)"
      />
    </div>

  </div>
</template>

<script>
import SettingsEventRangeInput from "./SettingsEventRangeInput.vue"
import SettingsDetailSeries from "./SettingsDetailSeries.vue"

export default {
  props: ["series", "options"],
  components: { SettingsEventRangeInput, SettingsDetailSeries },
  data() {
    return {
      ranges: this.options.ranges || {},
      details: this.options.details || {}
    }
  },
  methods: {
    addOptions: function(obj) {
      this.ranges = {
        ...this.ranges,
        ...obj
      };
      this.$emit("add-options", { ranges: this.ranges });
    },
    updateDetails: function(obj) {
      this.details = {
        ...this.details,
        ...obj
      };
      this.$emit("add-options", { details: this.details });
    }
  }
}
</script>
