<template lang="html">
  <div class="">
    <ul class="f6 list pl0">
      <li
        v-for="question in Object.keys(data)"
        class="mb3"
      >
        <div class="b bb b--black-20 tl pb1 mb1">
          {{ question }}
        </div>
        <ul class="list pl0">
          <div v-for="answer in Object.keys(data[question])" class="mb1">
            {{ answer }} <span class="ph2 bg-blue br2 white fr">{{ data[question][answer] }}</span>
          </div>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["data"]
}
</script>

<style lang="css" scoped>
</style>
