<template lang="html">
  <div class="w-100">
    <div class="flex items-end">
      <div class="w-20 mr4">
        <label for="start-date" class="db mb1">Start date</label>
        <date-picker
          input-class="input-reset w-100 pa2 br2 b--black-40 ba"
          format="YYYY-MM-DD"
          value-type="format"
          :editable="false"
          :clearable="false"
          :disabled-date="fromToday"
          required
          v-model="start_date"
        />
      </div>
      <div class="w-20 mr4">
        <label for="end-date" class="db mb1">End date</label>
        <date-picker
          input-class="input-reset w-100 pa2 br2 b--black-40 ba"
          format="YYYY-MM-DD"
          value-type="format"
          :editable="false"
          :clearable="false"
          :disabled-date="fromToday"
          required
          v-model="end_date"
        />
      </div>
      <div v-if="hospitals.length" class="w-20 mr2">
        <label for="hospital" class="b db mb1">Hospital</label>
        <select
          class="w-100 f6 h2 ph1 br2 bg-white b--black-20 border-box"
          v-model="account_hospital_id"
        >
          <option :value="''">All</option>
          <option v-for="hospital in hospitals" :key="hospital.id" :value="hospital.id">
            {{ hospital.name }}
          </option>
        </select>
      </div>

      <button
        @click="update"
        class="pa2 ph4 ba br2 b--gray bg-gray white pointer dim"
      >
        Update
      </button>
    </div>

    <div v-if="Object.keys(data).length === 0">
      <p v-if="loading" class="gray">Loading...</p>
      <p v-else>No metrics data available for this project</p>
    </div>

    <div v-else>
      <div v-if="Object.keys(data.message_stats).length">
        <h3>Message stats</h3>
        <table class="w-100 center" cellspacing="0">
          <thead>
            <tr>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Delivered</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Opened</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Clicked</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Open Rate</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Emails Click Rate</th>
              <th class="fw6 bb b--black-20 tl pb2 pr3">Sms Tap Rate</th>
            </tr>
          </thead>
          <tbody class="lh-copy">
            <tr>
              <td class="pv2 pr3 bb b--black-20">{{ data.message_stats.all_delivered }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ data.message_stats.all_opened }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ data.message_stats.all_clicked }}</td>
              <td class="pv2 pr3 bb b--black-20">{{ displayRate(data.message_stats.open_rate) }}%</td>
              <td class="pv2 pr3 bb b--black-20">{{ displayRate(data.message_stats.emails_click_rate) }}%</td>
              <td class="pv2 pr3 bb b--black-20">{{ displayRate(data.message_stats.sms_tap_rate) }}%</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="data.survey_data" class="pb3">
        <h3>
          Survey data
          <span class="f5 normal ml3">
            <a class="blue link pointer" @click="export_data">export data</a>
          </span>
        </h3>
        <response-table :data="data.survey_data" />
      </div>

      <div v-if="data.respfb_data" class="pb3">
        <h3>Responsive feedback data</h3>
        <response-table :data="data.respfb_data" />
      </div>

      <div v-if="data.vote_data">
        <h3>Vote data</h3>
        <response-table :data="data.vote_data" />
      </div>
    </div>

  </div>
</template>

<script>
import ResponseTable from "./ResponseTable"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

export default {
  components: { ResponseTable, DatePicker },
  props: ["project", "hospitals"],
  data() {
    return {
      loading: true,
      data: {},
      start_date: "",
      end_date: "",
      account_hospital_id: ""
    }
  },
  mounted() {
    let today = new Date()
    this.start_date = new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString().split("T")[0]
    this.end_date = new Date(today.getFullYear(), today.getMonth(), 0).toISOString().split("T")[0]
    this.update()
  },
  computed: {
    params: function() {
      return {
        project_id: this.project.to_param,
        project_type: this.project.type,
        start_date: this.start_date,
        end_date: this.end_date,
        account_hospital_id: this.account_hospital_id
      }
    }
  },
  methods: {
    fromToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0))
    },
    update() {
      this.loading = true
      this.data = {}
      this.$http.get("/metrics.json", { params: this.params })
        .then(response => {
          this.data = response.data
          this.loading = false
        })
    },
    displayRate(value) {
      return value ? value.toFixed(2) : ""
    },
    export_data() {
      let msg = "A background process will generate your data with the " +
                "resulting report file placed into your documents section." +
                "Depending on the amount of data, note it may take a short " +
                "while for the document to become available." +
                "\n\nContinue?"
      if (confirm(msg)) {
        this.$http.post("/export_survey_data", this.params)
          .then(response => {
            window.location.href = "/documents"
          })
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
