<template lang="html">
  <div class="flex flex-column">
    <h3 class="mb0">Settings</h3>
    <component
      :is="kindComponent"
      :series="series"
      :options="options"
      @add-options="addOptions($event)"
    />

    <settings-hospital
      v-if="all_hospitals.length"
      :options="options"
      :all_hospitals="all_hospitals"
      @add-options="addOptions($event)"
    />

    <settings-schedule
      v-if="['Status', 'Detail', 'EventRange'].includes(kind)"
      :options="options"
      @add-options="addOptions($event)"
    />

    <div class="mt3">
      <button @click="changeStep(0)">
        Go back
      </button>
      <button :disabled="disableContinue" @click="changeStep(2)">
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import SettingsStatus from "./SettingsStatus.vue";
import SettingsSegment from "./SettingsSegment.vue";
import SettingsEvent from "./SettingsEvent.vue";
import SettingsEventRange from "./SettingsEventRange.vue";
import SettingsDetail from "./SettingsDetail.vue";
import SettingsSubscription from "./SettingsSubscription.vue";
import SettingsSchedule from "./SettingsSchedule.vue";
import SettingsHospital from "./SettingsHospital.vue";

export default {
  props: ["baseKind", "options", "series", "all_hospitals"],
  components: {
    SettingsStatus,
    SettingsSegment,
    SettingsEvent,
    SettingsEventRange,
    SettingsDetail,
    SettingsSubscription,
    SettingsSchedule,
    SettingsHospital
  },
  data() {
    return {
      kind: this.baseKind,
      kinds: ["Status", "Segment", "Event", "EventRange", "Detail", "Subscription"],
      sendoutDate: null,
    }
  },
  computed: {
    kindComponent: function() {
      return `Settings${this.kind}`;
    },
    disableContinue: function() {
      if (this.kind === "Status") {
        if (!this.options.statuses) {
          return true
        } else {
          let subs = this.options.statuses.subscribed
          let grads = this.options.statuses.graduated
          return !((subs && subs.length) || (grads && grads.length))
        }
      } else {
        return false
      }
    }
  },
  methods: {
    changeStep: function(val) {
      this.$emit("change-step", val);
    },
    addOptions: function(obj) {
      this.$emit("add-options", obj);
    }
  },
}
</script>

<style lang="css" scoped>
</style>
