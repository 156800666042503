<template lang="html">
  <div class="confirm-event flex flex-column">
    <p>
      Send to subscribers before, on or after the event for these series:
    </p>
    <div
      class=""
      v-for="item in series"
      v-if="options.checkedSeries.includes(item.to_param)"
      :key="item.to_param"
    >
      <b>{{ item.name }}:</b>
      {{ Math.abs(offsets[item.to_param]) || 0 }}
      {{ (offsets[item.to_param] == 1) ? "day" : "days" }}
      {{ (offsets[item.to_param] >= 0) ? "after" : "before" }}
      {{ item.event_name }}

      <p>Send to subscribers registered with matching signup field values:</p>
      <p v-html="humanizedFilter(item)"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["series", "options"],
  data() {
    return {
      offsets: this.options.offsets || {},
      details: this.options.details || {}
    }
  },
  methods: {
    humanizedFilter(series) {
      let details = this.details[series.to_param]
      if (!details) { return "N/A" }
      let text = ""
      Object.keys(details).forEach(function(id) {
        let label = series.signup_fields.find(field => field.id === parseInt(id)).label
        text += `${label}: ${details[id]}<br>`
      })
      return text
    }
  }
}
</script>

<style lang="css" scoped>
</style>
