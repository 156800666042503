<template>
  <div class="w-100">
    <div v-if="loaded">
      <select
        v-if="data.storyboards && data.storyboards.length > 1"
        class="w-100 mb3 f6 h2 ph1 br2 bg-white b--black-20 border-box"
        v-model="projectInfo"
      >
        <option selected value="">All pathways</option>
        <option v-for="storyboard in data.storyboards" :value="storyboard.id + ':Storyboard'">
          {{ storyboard.name }}
        </option>
      </select>
      <div class="mb4">
        <a v-for="tab in tabs"
          @click.prevent="setActive(tab)"
          :class="tabStyle(tab)"
          class="no-underline pointer br2 bn mr1 pa2 mv1 bg-animate border-box ttc"
        >
          {{ tab }}
        </a>
      </div>
      <div v-for="tab in tabs" :class="{ 'dn': !isActive(tab) }" :id="tab">
        <line-chart
          :data="data.chartData[tab]"
          :curve="false"
          :library="chartOptions"
          ytitle="# Subscribers"
          thousands=","
        />
      </div>
    </div>
    <div v-else class="center pv4 f6 light-silver tc">Loading...</div>
  </div>
</template>

<script>
  import ChartBase from './ChartBase.vue';

  export default {
    extends: ChartBase,
    data() {
      return {
        tabs: ["active", "new", "total"],
        activeTab: "active",
        chartOptions: {
          scales: { xAxes: [{ ticks: { source: 'data' } }] }
        }
      }
    },
    methods: {
    	isActive(tabItem) {
        return this.activeTab === tabItem
      },
      setActive(tabItem) {
        this.activeTab = tabItem
      },
      tabStyle(tabItem) {
        if (this.activeTab === tabItem) {
          return "bg-blue white"
        } else {
          return "bg-light-gray blue hover-bg-moon-gray"
        }
      }
    }
  }
</script>

<style></style>
