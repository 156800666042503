<template lang="html">
  <div class="w-100 flex">
    <div class="w-80 mr2">
      <autocomplete
        :search="search"
        placeholder="Search for subscriber..."
        :debounce-time="200"
        :auto-select="true"
        :get-result-value="getResultValue"
        @submit="handleSubmit"
      >
        <template #result="{ result, props }">
          <li
            v-bind="props"
            class="autocomplete-result"
          >
            <div class="">
              {{ result.name }}
              <small v-if="result.kind != 'patient'" class="silver ml1">
                {{ result.kind }}
              </small>
            </div>
          </li>
        </template>
      </autocomplete>
    </div>

    <select
      class="w-20 pa2 br2 b--black-20"
      v-model="kind"
      @change="filterKind"
    >
      <option value="">All</option>
      <option value="patient">Patient</option>
      <option value="staff">Staff</option>
    </select>
  </div>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue"
import "@trevoreyre/autocomplete-vue/dist/style.css"

export default {
  components: { Autocomplete },
  props: ["kind"],
  data() {
    return {
      subscribers: []
    }
  },
  methods: {
    search(input) {
      if (input.length < 1) { return [] }
      this.$http.get("/frontend/subscribers/search", { params: { search: input, kind: this.kind } })
        .then(res => { this.subscribers = res.data })
        .catch(err => {
          return []
          console.error(err)
        })
      return this.subscribers
    },
    getResultValue(result) {
      return result.name
    },
    handleSubmit(result) {
      if (!result) { return }
      window.location.href = `/text_messages/${result.to_param}`
    },
    filterKind() {
      window.location.href = `/text_messages?kind=${this.kind}`
    }
  }
}
</script>

<style lang="css" scoped>
</style>
