<template lang="html">
  <div class="">
    <h3>Confirmation</h3>

    <div
      class="pv2 ph1 ba br2 b--light-red bg-light-red white"
      v-if="error"
    >
      {{ error }}
    </div>

    <div class="bg-white ph4 pb4 mt2 ba b--near-white shadow-4 br2">
      <component
        :is="confirmComponent"
        :series="series"
        :options="options"
        @hook:mounted="estimatedAudience"
      />

      <div class="mt4" v-if="options.hospitals && options.hospitals.length">
        <p>Include only subscribers associated with these hospitals:</p>
        <p>{{ hospitalNames() }}</p>
      </div>

      <div class="mv3" v-if="options.scheduled == 'auto'">
        <div>Last day this campaign schedule will be active (optional)</div>
        <date-picker
          format="YYYY-MM-DD"
          value-type="format"
          v-model="endDate"
        />
      </div>

      <ul class="series list pl0">
        <li>
          <input type="checkbox" id="include_engaged" v-model="includeEngaged">
          <label for="include_engaged">
            Include subscribers that previously opened or clicked a message from this campaign.
          </label>
        </li>
      </ul>
    </div>

    <div class="mv3" v-if="options.scheduled == 'now'">
      Estimated audience: {{ audience }}
    </div>

    <div class="mt3">
      <button @click="changeStep(1)">
        Go back
      </button>
      <button :disabled="!options.scheduled || submitting" @click="submit()">
        Approve
      </button>
    </div>
  </div>
</template>

<script>
import ConfirmStatus from "./ConfirmStatus.vue";
import ConfirmEvent from "./ConfirmEvent.vue";
import ConfirmEventRange from "./ConfirmEventRange.vue";
import ConfirmSegment from "./ConfirmSegment.vue";
import ConfirmDetail from "./ConfirmDetail.vue";
import ConfirmSubscription from "./ConfirmSubscription.vue";
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

export default {
  props: ["baseKind", "options", "series", "campaign", "all_hospitals"],
  components: {
    ConfirmStatus,
    ConfirmEvent,
    ConfirmEventRange,
    ConfirmSegment,
    ConfirmDetail,
    ConfirmSubscription,
    DatePicker
  },
  data() {
    return {
      kind: this.baseKind,
      submitting: false,
      error: null,
      audience: 0,
      endDate: null,
      includeEngaged: null,
    }
  },
  computed: {
    confirmComponent: function() {
      return `Confirm${this.kind}`;
    },
    campaignSchedule: function() {
      const kind = this.kind
                      .match(/[A-Z]{2,}(?=[A-Z][a-z]+|\b)|[A-Z]?[a-z]+|[A-Z]+/g)
                      .map(x => x.toLowerCase())
                      .join('_')
      return {
        campaign_schedule: {
          kind,
          sendout_date: this.options.sendoutDate,
          statuses: this.options.statuses,
          offsets: this.options.offsets,
          ranges: this.options.ranges,
          details: this.options.details,
          scheduled: this.options.scheduled,
          hospitals: this.options.hospitals,
          end_date: this.endDate,
          include_engaged: this.includeEngaged,
        }
      }
    }
  },
  methods: {
    changeStep: function(val) {
      this.$emit("change-step", val);
    },
    estimatedAudience() {
      if (this.options.scheduled != 'now') { return }

      this.$http
          .post(`/frontend/campaigns/${this.campaign.to_param}/schedules/audience`,
            this.campaignSchedule
          )
          .then(res => {
            this.audience = res.data
          })
    },
    submit: function() {
      this.submitting = true;
      this.$http
          .post(
            `/frontend/campaigns/${this.campaign.to_param}/schedules`,
            this.campaignSchedule
          )
          .then(res => {
            window.location.href = `/campaigns/${this.campaign.to_param}/schedules`;
          })
          .catch(err => {
            this.error = err.message;
          })
    },
    hospitalNames() {
      let all_hospitals = this.all_hospitals
      return this.options.hospitals.map(function(id) {
        return all_hospitals.find(hospital => hospital.id === parseInt(id)).name
      }).join(", ")
    }
  }
}
</script>

<style lang="css" scoped>
</style>
