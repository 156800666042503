<template lang="html">
  <div class="settings-details">
    <div
      class="flex flex-column mb3 bb b--gray pb3"
      v-for="item in series"
      v-if="options.checkedSeries.includes(item.to_param) && item.signup_fields.length"
      :key="item.to_param"
    >
      <h4>{{ item.name }}</h4>

      <settings-detail-series
        :series="item"
        :options="options"
        @update-details="updateDetails($event)"
      />
    </div>
  </div>
</template>

<script>
import SettingsDetailSeries from "./SettingsDetailSeries.vue";

export default {
  props: ["series", "options"],
  components: { SettingsDetailSeries },
  data() {
    return {
      details: this.options.details || {}
    }
  },
  methods: {
    updateDetails: function(obj) {
      this.details = {
        ...this.details,
        ...obj
      };
      this.$emit("add-options", { details: this.details })
    }
  },
}
</script>

<style lang="css" scoped>
</style>
