<template lang="html">
  <div class="">
    <h3>Audience</h3>
    <ul class="type list pl0">
      <li>
        <input type="radio" id="status" value="Status" v-model="kind">
        <label for="status">
          Send to subscribers based on a subscription status
        </label>
      </li>
      <li>
        <input type="radio" id="segment" value="Segment" v-model="kind">
        <label for="segment">
          Send to subscribers before or after a storyboard message
        </label>
      </li>
      <li>
        <input type="radio" id="event" value="Event" v-model="kind">
        <label for="event">
          Send to subscribers before or after the event
        </label>
      </li>
            <li>
        <input type="radio" id="event-range" value="EventRange" v-model="kind">
        <label for="event-range">
          Send to subscribers with an event between two dates
        </label>
      </li>
      <li>
        <input type="radio" id="detail" value="Detail" v-model="kind">
        <label for="detail">
          Send to subscribers that registered with matching signup field values
        </label>
      </li>
      <li>
        <input type="radio" id="subscription" value="Subscription" v-model="kind">
        <label for="subscription">
          Send to subscribers after the subscription
        </label>
      </li>
    </ul>

    <ul class="series list pl0">
      <li v-for="(item, ix) in series" :key="item.to_param">
        <input type="checkbox" :id="item.to_param" :value="item.to_param" v-model="checkedSeries">
        <label :for="item.to_param">{{ item.name }}</label>
      </li>
    </ul>

    <button :disabled="kind === null || checkedSeries.length === 0" @click="nextStep()">
      Continue
    </button>
  </div>
</template>

<script>
export default {
  props: ["baseKind", "options", "series"],
  data() {
    return {
      kind: this.baseKind,
      checkedSeries: this.options.checkedSeries || [],
    }
  },
  methods: {
    nextStep: function() {
      if (["Segment", "Event", "Subscription"].includes(this.kind)) {
        this.$emit("add-options", { scheduled: "auto" });
      }
      this.$emit("add-options", { checkedSeries: this.checkedSeries });
      this.$emit("change-step", 1);
    },
  },
  watch: {
    kind: function() {
      this.$emit("change-kind", this.kind);
      this.checkedSeries = [];
    },
    checkedSeries: function() {
      this.$emit("reset-options");
    },
  },
}
</script>

<style lang="css" scoped>
</style>
