<template>
  <div class="mv4">
    <h3>Subscriptions</h3>
    <a
      v-if="subscriber.status !== 'unsubscribed'"
      class="blue link pointer mb1"
      @click="showNew = !showNew"
    >
      Add a subscription
    </a>
    <div v-show="showNew" class="flex items-center mv1">
      <select
        class="input-reset w-100 pa2 mt1 br2 b--black-40 ba"
        required
        v-model="selectedSeries"
      >
        <option v-for="s in series" :key="s.to_param" :value="s.to_param">
          {{ s.name }}
        </option>
      </select>
      <a class="blue link pointer ml2" @click="newSubscription">Choose</a>
    </div>
    <div v-if="!loaded" class="light-silver">
      Loading...
    </div>
    <div v-else class="">
      <div v-if="items.length">
        <ul class="list pl0">
          <subscription-list-item
            v-for="(item, ix) in items"
            :key="ix"
            :item="item"
            @unsubscribe="unsubscribe(item)"
          />
        </ul>
      </div>
      <div v-else class="light-silver">
        There are no subscriptions for this subscriber.
      </div>

      <div class="mt4">
        <a class="blue link pointer" @click="showMore" v-if="!last_page">
          Show more
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionListItem from "./SubscriptionListItem.vue";

export default {
  components: {
    SubscriptionListItem
  },
  props: ["subscriber"],
  data() {
    return {
      loaded: false,
      error: "",
      items: [],
      page: 1,
      last_page: true,
      showNew: false,
      series: [],
      selectedSeries: null
    }
  },
  mounted() {
    this.$http
      .get("/frontend/series")
      .then((res) => {
        this.series = res.data
        this.selectedSeries = this.series[0].to_param
      })

    this.$http
      .get(`/frontend/subscribers/${this.subscriber.to_param}/subscriptions`)
      .then((res) => {
        this.items = res.data.subscriptions;
        this.page = res.data.current_page;
        this.last_page = res.data.last_page;
      })
      .catch((err) => (this.error = err.message))
      .finally(() => (this.loaded = true));
  },
  methods: {
    showMore() {
      this.last_page = true; // hide on click
      const nextPage = this.page + 1;
      this.$http
        .get(
          `/frontend/subscribers/${this.subscriber.to_param}/subscriptions?page=${nextPage}`
        )
        .then((res) => {
          this.items.push(...res.data.subscriptions);
          this.page = res.data.current_page;
          this.last_page = res.data.last_page;
        })
        .catch((err) => (this.error = err.message))
        .finally(() => (this.loaded = true));
    },
    newSubscription() {
      let url = `/subscriptions/new?series_id=${this.selectedSeries}&subscriber_id=${this.subscriber.to_param}`
      window.location.href = url
    },
    unsubscribe(item) {
      this.$http.patch(`/frontend/subscriptions/${item.to_param}/unsubscribe`)
        .then((res) => {
          let index = this.items.indexOf(item)
          item.inactive = true
          item.status = "unsubscribed"
          this.items[index] = item
        })
    }
  }
};
</script>

<style></style>
