<template lang="html">
  <div class="flex flex-column">
    <div
      class="series mb1 bb b--gray pb3"
      v-for="(item, ix) in series"
      :id="ix"
      :key="ix"
      v-if="options.checkedSeries.includes(item.to_param)"
    >
      <h4>{{ item.name }}</h4>

      <ul class="storyboards list pl0">
        <div v-for="(storyboard, ix) in item.storyboards" :key="ix">
          <li class="mb1">
            <input type="checkbox" :id="storyboard.to_param + '-subscribed'" :value="storyboard.to_param" v-model="statuses.subscribed">
            <label :for="storyboard.to_param + '-subscribed'"><b>{{ storyboard.name }}</b> subscribed</label>
          </li>
          <li class="mb1">
            <input type="checkbox" :id="storyboard.to_param + '-graduated'" :value="storyboard.to_param" v-model="statuses.graduated">
            <label :for="storyboard.to_param + '-graduated'"><b>{{ storyboard.name }}</b> graduated</label>
          </li>
        </div>
      </ul>

      <settings-detail-series
        :series="item"
        :options="options"
        @update-details="updateDetails($event)"
      />
    </div>

  </div>
</template>

<script>
import SettingsDetailSeries from "./SettingsDetailSeries.vue";

export default {
  props: ["series", "options"],
  components: { SettingsDetailSeries },
  data() {
    return {
      statuses: this.options.statuses || { subscribed: [], graduated: [] },
      details: this.options.details || {}
    }
  },
  methods: {
    updateDetails: function(obj) {
      this.details = {
        ...this.details,
        ...obj
      };
      this.$emit("add-options", { details: this.details });
    }
  },
  watch: {
    statuses: {
      handler: function(val) {
        this.$emit("add-options", { statuses: this.statuses });
      },
      deep: true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
