import { render, staticRenderFns } from "./SettingsSegmentSeries.vue?vue&type=template&id=579fae5b&scoped=true&lang=html&"
import script from "./SettingsSegmentSeries.vue?vue&type=script&lang=js&"
export * from "./SettingsSegmentSeries.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "579fae5b",
  null
  
)

export default component.exports