<template>
  <div class="w-100">
    <div v-if="loaded">
      <div v-if="gotData">
        <select
          v-if="data.storyboards && data.storyboards.length > 1"
          class="w-100 mb3 f6 h2 ph1 br2 bg-white b--black-20 border-box"
          v-model="projectInfo"
        >
          <option selected value="">All pathways</option>
          <option v-for="storyboard in data.storyboards" :value="storyboard.id + ':Storyboard'">
            {{ storyboard.name }}
          </option>
        </select>
        <div class="flex items-start">
          <section class="hidden ba br2 b--moon-gray mr3 w-100">
            <div class="bg-light-gray f5 mv0 pv2 ph3 tc">
              Total
            </div>
            <div class="ph3 pv3 bt b--moon-gray bg-white f4 tc">
              {{ data.chartData.total }}
            </div>
          </section>
          <section class="hidden ba br2 b--moon-gray mh1 w-100">
            <div class="bg-light-gray f5 mv0 pv2 ph3 tc">
              Active
            </div>
            <div class="ph3 pv3 bt b--moon-gray bg-white f4 tc">
              {{ data.chartData.active_rate }}%
            </div>
          </section>
          <section class="hidden ba br2 b--moon-gray ml3 w-100">
            <div class="bg-light-gray f5 mv0 pv2 ph3 tc">
              Graduate
            </div>
            <div class="ph3 pv3 bt b--moon-gray bg-white f4 tc">
              {{ data.chartData.grad_rate }}%
            </div>
          </section>
        </div>
      </div>
      <div v-else class="center pv4 f6 light-silver tc">
        <div v-if="error">{{ error }}</div>
        <div v-else>No unsubscribe data</div>
      </div>
    </div>
    <div v-else class="center pv4 f6 light-silver tc">Loading...</div>
  </div>
</template>

<script>
  import ChartBase from './ChartBase.vue';

  export default {
    extends: ChartBase,
    computed: {
      gotData: function() {
        return Object.keys(this.data.chartData).length > 0
      }
    }
  }
</script>

<style></style>
