<template>
  <div class="w-100">
    <div v-if="loaded">
      <div class="mb3">
        <a v-for="tab in tabs"
          @click.prevent="setActive(tab)"
          :class="tabStyle(tab)"
          class="no-underline pointer br2 bn mr1 pa2 mv1 bg-animate border-box ttc"
        >
          {{ tab === "storyboards" ? "pathways" : tab }}
        </a>
      </div>
      <div v-for="tab in tabs" :class="{ 'dn': !isActive(tab) }" :id="tab">
        <select
          v-if="(tab != 'totals') && (data[tab].length > 1)"
          class="w-100 mb2 f6 h2 ph1 br2 bg-white b--black-20 border-box"
          v-model="selectInfo[tab]"
        >
          <option selected value="">All {{ tab === "storyboards" ? "pathways" : tab }}</option>
          <option
            v-for="project in data[tab]"
            :value="project.id + ':' + tab"
          >
            {{ project.name }}
          </option>
        </select>
        <line-chart
          :data="chartData[tab]"
          :curve="false"
          :library="chartOptions"
          ytitle="# Messages"
          thousands=","
        />
      </div>
    </div>
    <div v-else class="center pv4 f6 light-silver tc">Loading...</div>
  </div>
</template>

<script>
  import ChartBase from './ChartBase.vue';

  export default {
    extends: ChartBase,
    data() {
      return {
        chartData: {},
        selectInfo: {storyboards: "", campaigns: ""},
        tabs: ["totals", "storyboards", "campaigns"],
        activeTab: "totals",
        chartOptions: {
          scales: { xAxes: [{ ticks: { source: 'data' } }] }
        }
      }
    },
    methods: {
    	isActive(tabItem) {
        return this.activeTab === tabItem
      },
      setActive(tabItem) {
        this.activeTab = tabItem
      },
      tabStyle(tabItem) {
        if (this.activeTab === tabItem) {
          return "bg-blue white"
        } else {
          return "bg-light-gray blue hover-bg-moon-gray"
        }
      }
    },
    computed: {
      storyboardsSelect() {
        return this.selectInfo.storyboards
      },
      campaignsSelect() {
        return this.selectInfo.campaigns
      }
    },
    watch: {
      storyboardsSelect(val) {
        this.projectInfo = val
      },
      campaignsSelect(val) {
        this.projectInfo = val
      },
      data(val) {
        var tags = ["totals", "storyboards", "campaigns"]
        tags.forEach((tag) => {
          if (val.chartData[tag] !== undefined) {
            this.chartData[tag] = val.chartData[tag]
          }
        })
      }
    }
  }
</script>

<style></style>
