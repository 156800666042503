import { render, staticRenderFns } from "./ConfirmSubscription.vue?vue&type=template&id=53fb0cee&scoped=true&lang=html&"
import script from "./ConfirmSubscription.vue?vue&type=script&lang=js&"
export * from "./ConfirmSubscription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53fb0cee",
  null
  
)

export default component.exports