<template lang="html">
  <div class="settings-schedule flex flex-column">
    <h4>Schedule</h4>

    <div class="now">
      <input type="radio" id="now" value="now" v-model="scheduled">
      <label for="now">Send Now</label>
    </div>
    <div class="future mt1">
      <input type="radio" id="future" value="future" v-model="scheduled">
      <label for="future">Select Future Date</label>
    </div>

    <div v-if="scheduled == 'future'" class="w-30 mt2">
      <date-picker
        format="YYYY-MM-DD"
        value-type="format"
        :editable="false"
        :clearable="false"
        :disabled-date="notBeforeTomorrow"
        v-model="sendoutDate"
        @change="updateSchedule"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

export default {
  props: ["options"],
  components: { DatePicker },
  data() {
    return {
      today: new Date().toISOString().split("T")[0],
      scheduled: this.options.scheduled || "now",
      sendoutDate: this.options.sendoutDate || this.today
    }
  },
  mounted() {
    this.sendoutDate = this.sendoutDate || this.today
    this.updateSchedule()
  },
  methods: {
    updateSchedule() {
      this.$emit("add-options", this.schedule)
    },
    notBeforeTomorrow(date) {
      return date < new Date(this.tomorrow)
    }
  },
  computed: {
    tomorrow: function() {
      let date = new Date(this.today)
      date.setDate(date.getDate() + 1)
      return date.toISOString().split("T")[0]
    },
    schedule: function() {
      return { scheduled: this.scheduled, sendoutDate: this.sendoutDate }
    }
  },
  watch: {
    scheduled: function(val) {
      if (val === "now") { this.sendoutDate = this.today }
      else { this.sendoutDate = this.tomorrow }
      this.updateSchedule()
    }
  }
}
</script>

<style lang="css" scoped>
</style>
