<template lang="html">
  <div class="">
    <label :for="field.label" class="db lh-copy">
      {{ field.label }} <span v-if="field.required" class="red">*</span>
    </label>
    <input
      v-if="field.field_type == 'text'"
      type="text"
      class="input-reset w-100 pa2 mt1 br2 b--black-40 ba"
      :required="field.required"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
    <div v-if="field.field_type == 'date'">
      <date-picker
        input-class="input-reset w-100 pa2 mt1 br2 b--black-40 ba"
        format="YYYY-MM-DD"
        value-type="format"
        :clearable="false"
        :required="field.required"
        :value="value"
        @input="$emit('input', $event)"
      />
      <small class="gray">Format: YYYY-MM-DD</small>
    </div>
    <select
      v-if="field.field_type == 'dropdown'"
      class="input-reset w-100 pa2 mt1 br2 b--black-40 ba"
      :required="field.required"
      :value="value"
      @change="$emit('input', $event.target.value)"
    >
      <option value="">Select one...</option>
      <option v-for="option in field.options_for_select" :key="option" :value="option" :selected="value == option">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"

export default {
  name: "SignupField",
  components: { DatePicker },
  props: ["field", "value"],
}
</script>

<style lang="css" scoped>
  .mx-datepicker { width: 100% }
</style>
