<template>
  <li class="pt2 bt b--black-20" :class="item.status == 'archived' ? 'mb3 moon-gray' : 'mb3'">
    <div class="">
      <span class="b">{{ item.series.name }}</span>
      <a v-if="!this.item.inactive" @click="edit" class="ml2 blue link pointer">edit</a>
    </div>
    <div class="">
      {{ item.series.event_name }}: {{ item.event_date }}
    </div>
    <div class="mb2">
      <span :class="item.status">{{ item.status }}</span>
      / {{ item.endpoint }}
      <a
        v-if="!this.item.inactive"
        class="ml2 pv0 ph2 br2 bg-light-red black f7 pointer"
        @click="unsubscribe"
      >
        unsubscribe
      </a>
    </div>
    <div
      class=""
      v-for="detail in item.details"
      v-if="detail.value.length"
    >
      {{ detail.signup_field.label }}: {{ detail.value }}
    </div>
  </li>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    edit() {
      window.location.href = `/subscriptions/${this.item.to_param}/edit`
    },
    unsubscribe() {
      if (confirm("This will permanently unsubscribe this subscription. Are you sure?")) {
        this.$emit("unsubscribe")
      }
    }
  }
}
</script>

<style lang="css" scoped>
.unsubscribed { color: #FF725C; }
.subscribed { color: #333333; }
.graduated { color: #999999; }
</style>
